<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        id: {
            type: Number,
            default: null,
        },
        provider: {
            type: Object,
            default: () => ({}),
        },
    },
    provide() {
        return {
            get: async (id) => {
                if (!this.getItemsById[id]) {
                    await this.get({ id })
                }

                // if there's no cfgSisSystemId, this is a new configuration.
                if (!this.getItemsById[id]?.cfgSisSystemId) {
                    return {
                        sisApiUrl: null,
                        sisApiKey: null,
                        sisApiSecret: null,
                        sisApiEnabled: 'true',
                        qFileName: null,
                        sisApi: null,
                        sisSyncTime: '18:00',
                    }
                }
                return { ...this.getItemsById[id] }
            },

            submit: async (data) => {
                const currentData = this.getItemsById[this.districtId]
                // validate API credentials.
                if (
                    !currentData ||
                    data.sisApiUrl !== currentData.sisApiUrl ||
                    data.sisId !== currentData.sisId ||
                    data.sisApiKey !== currentData.sisApiKey ||
                    data.sisApiSecret !== currentData.sisApiSecret ||
                    data.qFilename !== currentData.qFilename
                ) {
                    this.$emit('submissionState', 'Validating')
                    const valid = await this.validateApi({
                        provider: this.provider,
                        data,
                    })
                    if (valid === false) {
                        const errors = {
                            sisApiUrl: 'Enter a valid URL',
                            sisApiKey: 'Enter a valid API Key',
                            sisApiSecret: 'Enter a valid API secret',
                            sisId: 'Enter a valid ID',
                            qFilename: 'Enter a valid qFilename',
                        }
                        this.$emit('submissionState', 'Submit')
                        this.$forceUpdate()

                        return { errors }
                    }
                }

                this.$emit('submissionState', 'Submitting')

                try {
                    const resp = await this.patch({
                        id: this.districtId,
                        data: {
                            cfgSisSystemId: this.provider.id,
                            ...data,
                        },
                    })

                    this.$emit('submitted', resp.data)
                } catch {
                } finally {
                    this.$emit('submissionState', 'Submit')
                }
            },
        }
    },
    computed: {
        ...mapGetters({
            getItemsById: 'sisApiConfig/getItemsById',
            districtId: 'getDistrictId',
        }),
    },

    methods: {
        ...mapActions({
            get: 'sisApiConfig/get',
            patch: 'sisApiConfig/patch',
            validateApi: 'sisSystem/validateApi',
        }),
    },
    render() {
        return this.$slots.default
    },
}
</script>

<style></style>
