<template>
    <label
        class="v-label theme--light text-field"
        :style="{ flexWrap: noWrap ? 'nowrap' : 'wrap' }"
    >
        <span class="label-wrap">{{ getLabel }}</span>
        <v-select
            :id="fieldKey"
            :key="fieldKey"
            :value="formValue"
            v-bind="$attrs"
            :rules="formRules"
            :error-messages="form.errors[fieldKey]"
            outlined
            dense
            :hide-details="hideDetails"
            @change="(v) => change(v)"
            v-on="$listeners"
        >
            <template v-for="(_, name) in $scopedSlots" #[name]="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
            <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>
        </v-select>
    </label>
</template>

<script>
import { humanizeStr } from '@/helpers/form/formatting'

export default {
    name: 'DropDown',
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        // don't set for new workflow.
        form: {
            type: Object,
            default: () => ({
                data: null,
                rules: null,
                errors: [],
            }),
        },
        fieldKey: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        resetValidation: {
            type: Function,
            default: () => {
                return () => {}
            },
        },
        hideDetails: {
            type: String,
            default: 'auto',
        },
        caseInsensitiveMatch: {
            type: Boolean,
            default: false,
        },

        value: {
            type: [String, Number, Object],
            default: '',
        },
        rules: {
            type: Array,
            default: undefined,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getLabel() {
            return this.label ? this.label : humanizeStr(this.fieldKey)
        },
        formValue() {
            if (this.form.data) {
                return this.caseInsensitiveMatch
                    ? this.form.data[this.fieldKey]?.toLowerCase()
                    : this.form.data[this.fieldKey]
            }

            return this.caseInsensitiveMatch ? this.value?.toLowerCase() : this.value
        },
        formRules() {
            if (this.form.data) {
                return this.form.rules[this.fieldKey]
            }
            return this.rules
        },
    },
    methods: {
        change(v) {
            if (this.form.data) {
                this.form.data[this.fieldKey] = this.$attrs.type === 'number' ? parseInt(v) : v
                delete this.form.errors[this.fieldKey]
            }
            this.$emit('change', v)
        },
    },
}
</script>
<style lang="scss" scoped>
.text-field {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &::v-deep {
        .v-input {
            max-width: 100%;
            width: 400px;

            .v-select__selection--disabled {
                color: rgb(5, 15, 45);
            }
        }
        .v-icon {
            font-size: 14px;
        }
        .v-input__prepend-inner,
        .v-input__append-inner,
        .v-input__append-outer {
            margin-top: 7px;
        }
    }

    .label-wrap {
        width: 150px;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}
</style>
