<template>
    <v-btn v-bind="$attrs" :disabled="!hasRole(allowedRoles) || disabled" v-on="$listeners">
        <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>
    </v-btn>
</template>

<script>
import allowedRolesMixin from '@/components/security/allowedRolesMixin'

export default {
    name: 'GatedBtn',
    mixins: [allowedRolesMixin],
}
</script>
