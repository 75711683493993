<template>
    <v-dialog
        v-bind="$attrs"
        max-width="696"
        :value="value"
        @input="(v) => $emit('input', v)"
        v-on="$listeners"
        @keydown.esc="$emit('input', false)"
        @keydown.enter="$refs.form.doSubmit"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs" />
        </template>
        <sis-provider-provider
            :id="id"
            :provider="provider"
            @submissionState="(s) => (submissionState = s)"
            @submitted="$emit('input', false)"
        >
            <form-factory
                :id="id"
                ref="form"
                :disabled="savingDistrict || submissionState === 'Validating'"
                :title="title"
                :inputs="inputs"
                :submit-label="submissionState"
                body-width="560px"
                class="form"
                data-cy="sis-config-form"
                @closed="() => $emit('input', false)"
                @change="change"
                @keypress="change"
            >
                <template #card-text-prepend>
                    <v-card class="d-flex align-end mt-4" flat>
                        <span class="card-prepend">Setup Details</span>
                        <v-spacer />
                        <img :src="img" height="60" />
                    </v-card>
                </template>
                <template #actions-left>
                    <zendesk-button v-if="allowRequestFromCustomer" />
                </template>
                <template #actions-right="{ submitHandler, disabled }">
                    <v-btn outlined @click="() => $emit('input', false)"> Close </v-btn>
                    <v-btn :disabled="disabled" color="primary" @click="submitHandler">
                        {{ submissionState }}
                    </v-btn>
                </template>
            </form-factory>
        </sis-provider-provider>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormFactory from '@/components/formFactory/FormFactory.vue'
import ZendeskButton from './ZendeskButton.vue'
import SisProviderProvider from './SisProviderProvider'

export default {
    name: 'ApiConfigDialog',
    components: {
        FormFactory,
        ZendeskButton,
        SisProviderProvider,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        provider: {
            type: Object,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        openedCustomerRequest: false,
        valid: false,
        // This is the submission button text, but also has
        // several side purposes including for validation.
        submissionState: 'Save',
    }),
    computed: {
        ...mapGetters({
            id: 'getDistrictId',
        }),
        title() {
            return `Set Up SIS Import | ${this.provider.name} API`
        },
        // @todo add this to SisProvider configuration.
        allowRequestFromCustomer() {
            return ['Aeries'].includes(this.provider.name) //only allow aeries for now
        },
        img() {
            try {
                return require(`@/assets/sis-api-logos/Logo-${this.provider.name}.png`)
            } catch (e) {
                // default to Plug icon.
                return require(`@/assets/sis-api-logos/Logo-Plug.png`)
            }
        },
        inputs() {
            try {
                return require(`@/helpers/form/definitions/sis-providers/${this.provider.name}.js`)
                    .default
            } catch {
                return require('@/helpers/form/definitions/sis-providers/default.js').default
            }
        },
    },
    methods: {
        change() {
            this.$refs.form.resetValidation()
        },
    },
}
</script>
<style scoped>
.time-appended {
    color: rgba(0, 0, 0, 0.6);
}

.form >>> .sync-time {
    justify-content: start;
}

.form >>> .v-input--switch .v-label {
    width: 140px;
}

.form >>> .sync-time .v-input__control {
    max-width: 125px;
}

.form >>> .sync-time .v-input:after {
    content: '(EST)';
    margin: auto 3px auto;
    color: rgba(192, 195, 207, 1);
    font-size: 12px;
}

.card-prepend {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: rgba(43, 57, 99, 1);
    font-family: 'CerebriSans-Medium', 'CerebriSans', 'Helvetica Neue', Arial, sans-serif;
}
</style>
