import { DateTime } from 'luxon'
import DropDown from '@/components/form/DropDown'
import TextField from '@/components/form/TextField'
import { VSwitch } from 'vuetify/lib'
import { between } from '@/helpers/form/validation'
import { timeItems } from './default'

export default [
    {
        component: TextField,
        name: 'sisApiUrl',
        label: 'Aequitas (Q) Domain',
        rules: [(v) => between(v, 0, 255)],
        options: {
            props: {
                'hide-details': 'auto',
                'no-wrap': true,
            },
        },
    },
    {
        component: TextField,
        name: 'sisApiKey',
        label: 'Key',
        rules: [(v) => between(v, 0, 255)],
        options: {
            props: {
                'hide-details': 'auto',
                'no-wrap': true,
            },
        },
    },
    {
        component: TextField,
        name: 'sisApiSecret',
        label: 'Secret',
        rules: [(v) => between(v, 0, 1000)],
        options: {
            props: {
                'hide-details': 'auto',
                'no-wrap': true,
            },
        },
    },
    {
        component: TextField,
        name: 'qFilename',
        label: 'Filename',
        options: {
            props: {
                'hide-details': 'auto',
                'no-wrap': true,
            },
        },
    },
    {
        component: DropDown,
        name: 'sisSyncTime',
        label: 'Daily SIS Sync Time',
        class: 'sync-time',
        options: {
            props: {
                items: timeItems({ minutes: 15 }),
                'item-text': 'text',
                'item-value': 'value',
                'no-wrap': true,
            },
            attrs: {
                class: 'sync-time',
            },
        },
    },
    {
        component: VSwitch,
        name: 'sisApiEnabled',
        label: 'SIS API Enabled',
        options: {
            props: {
                'true-value': 'true',
                'false-value': 'false',
                inset: true,
            },
        },
    },
]
