<template>
    <base-dialog
        v-model="openedCustomerRequest"
        :confirm-action="saveRequestFromCustomer"
        title="Confirm request from customer"
    >
        <template v-slot:content>
            <div class="my-4 ml-6">Would you like to submit a Zendesk ticket to the customer?</div>
        </template>
        <template v-slot:activator="{ on, attrs }">
            <gated-btn
                v-bind="attrs"
                outlined
                :disabled="!hasValidDistrictContact"
                :allowed-roles="['ROLE_ILLUMINATE_EMPLOYEE']"
                v-on="on"
            >
                Request from customer
            </gated-btn>
        </template>
    </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/modal/BaseDialog'
import GatedBtn from '@/components/security/GatedButton'

import { errorSnackbar, successSnackbar } from '@/helpers/snackbar'

export default {
    name: 'ZendeskButton',
    components: {
        BaseDialog,
        GatedBtn,
    },
    props: {
        provider: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        openedCustomerRequest: false,
    }),
    computed: {
        ...mapGetters({
            hasValidDistrictContact: 'hasValidDistrictContact',
            districtId: 'getDistrictId',
            lea: 'getDistrictLea',
            getRequestFromCustomerErrors: 'district/getErrors',
        }),
    },
    methods: {
        ...mapActions({
            requestFromCustomer: 'district/requestFromCustomer',
        }),
        async saveRequestFromCustomer() {
            await this.requestFromCustomer({
                id: this.districtId,
                lea: this.lea,
                data: { cfgSisSystemId: this.provider.id },
            })

            if (this.getRequestFromCustomerErrors.length > 0) {
                await errorSnackbar({
                    subtext: this.getRequestFromCustomerErrors.join(', '),
                })
            } else {
                await successSnackbar({
                    subtext: `Zendesk ticket successfully created`,
                })
            }

            this.openedCustomerRequest = false
            this.opened = false
        },
    },
}
</script>

<style></style>
