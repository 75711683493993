<template>
    <v-card class="mx-auto" :loading="submitting">
        <slot name="header">
            <v-card-title>
                <span>{{ title }}</span>
                <v-spacer></v-spacer>
                <v-icon color="primary" @click="$emit('closed')"> fal fa-times </v-icon>
            </v-card-title>
            <v-divider />
        </slot>
        <v-card-text :style="{ width: bodyWidth, margin: 'auto' }">
            <slot name="card-text-prepend" />
            <v-form ref="formRef" v-model="valid" :disabled="disabled || submitting">
                <v-row>
                    {{
            /* the columns should be fairly static so I feel safe using idx.
             it shouldn't require much work to generate an id if needed */
                    }}
                    <v-col
                        v-for="(col, idx) in cols"
                        :key="idx"
                        v-bind="col.column.props"
                        class="form-column"
                    >
                        <component
                            :is="field.component"
                            v-for="field in col.fields"
                            :id="`${_uid}-${field.name}`"
                            :key="field.name"
                            v-model="data[field.name]"
                            :label="field.label"
                            v-bind="{
                                ...field.options.props,
                                ...field.options.attrs,
                            }"
                            :rules="field.rules"
                            :data-cy="field.name"
                            :error-messages="errorMessages[field.name]"
                            @clearValidation="(e) => resetValidation()"
                            @change="(e) => $emit('change', { field: field.name, event: e })"
                            @keypress="(e) => $emit('keypress')"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <slot name="actions">
            <v-divider />
            <v-card-actions>
                <slot name="actions-left" />
                <v-spacer />
                <slot
                    name="actions-right"
                    :submit-handler="doSubmit"
                    :disabled="!valid || disabled || submitting"
                    :submitLabel="submitLabel"
                >
                    <v-btn
                        :disabled="!valid || disabled || submitting"
                        color="primary"
                        @click="doSubmit"
                    >
                        {{ submitLabel }}
                    </v-btn>
                </slot>
            </v-card-actions>
        </slot>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

const defaultField = () => ({
    component: null,
    label: '',
    name: '',
    options: {},
})

const defaultColumn = () => ({
    props: {
        cols: 12,
    },
})

export default {
    name: 'FormFactory',
    inject: ['get', 'submit'],
    inheritAttrs: false,
    props: {
        inputs: {
            default: () => [],
            type: Array,
        },
        id: {
            default: null,
            type: [Number, String],
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        title: {
            type: String,
            default: '',
        },
        submitLabel: {
            type: String,
            default: 'Submit',
        },
        bodyWidth: {
            type: String,
            default: 'auto',
        },
        skipValidation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            data: {},
            errorMessages: {},
            success: false,
            valid: false,
            submitting: false,
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
        }),
        cols() {
            let fields = [...this.inputs]
            // check if form has columns
            if (!fields[0]?.column) {
                fields = [
                    {
                        column: defaultColumn(),
                        fields: fields,
                    },
                ]
            }

            return fields.map((col) => ({
                ...col,
                fields: col.fields
                    .filter((field) => !field.roles || this.hasRole(field.roles))
                    .map((field) => ({
                        ...defaultField(),
                        ...field,
                    })),
            }))
        },
    },
    allFields() {
        return this.cols.reduce((acc, c) => {
            return acc.push(...c.fields)
        }, [])
    },
    mounted() {
        this.setup()
    },
    methods: {
        async setup() {
            this.data = (await this.get(this.id)) ?? {}
        },
        async doSubmit() {
            this.submitting = true
            if (!this.skipValidation && !this.$refs.formRef.validate()) {
                return
            }
            const result = await this.submit(this.data)
            this.submitting = false
            this.errorMessages = result?.errors || {}
        },
        async reset() {
            this.data = (await this.get(this.id)) ?? {}
            this.$refs.formRef.reset()
            this.resetValidation()
        },

        resetValidation() {
            this.$refs.formRef.resetValidation()
            this.errorMessages = {}
        },
    },
}
</script>
<style scoped>
/* match the gap provided by switches for other form elements. */
.form-column >>> label:first-child {
    margin-top: 16px;
}
</style>
